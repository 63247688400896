

export const RfsCategoryList =  [
    { label: "Vehicle Related", value: "Vehicle Related" },
    { label: "DP Related", value: "DP Related" },
    { label: "Rider Related", value: "Rider Related" },
    { label: "Ride Related", value: "Ride Related" },
    { label: "Tech Related", value: "Tech Related" },
    { label: "Emergency Logout", value: "Emergency Logout" }
];

export const RfsSubCategoryList =  {
    "Vehicle Related" : [
        { label: "AC / Heater not working", value: "AC / Heater not working" },
        { label: "Head Light not Working", value: "Head Light not Working" },
        { label: "Body noise", value: "Body noise" },
        { label: "Brake Issue", value: "Brake Issue" },
        { label: "Allignment Issue", value: "Allignment Issue" },
        { label: "Wiper not working", value: "Wiper not working" },
        { label: "Fastag / MCD Pass not available", value: "Fastag / MCD Pass not available" },
        { label: "L Mode", value: "L Mode" },
        { label: "Flat tyre", value: "Flat tyre" },
        { label: "Accident", value: "Accident" }
    ],
        "DP Related" : [
            { label: "Shift End", value: "Shift End" },
            { label: "Bio / Lunch Break", value: "Bio / Lunch Break" },
            { label: "Cab detained by Traffic Police", value: "Cab detained by Traffic Police" },
            { label: "DP at airport", value: "DP at airport" },
            { label: "DP at hub", value: "DP at hub" },
            { label: "Safety Concern due to DP", value: "Safety Concern due to DP" }
        ],
        "Rider Related" : [
            { label: "Rider Not responding", value: "Rider Not responding" },
            { label: "Rider wants to cancel ride", value: "Rider wants to cancel ride" },
            { label: "Misuse of Ride", value: "Misuse of Ride" },
            { label: "Wait due to flight / train delay", value: "Wait due to flight / train delay" },
            { label: "Change drop location", value: "Change drop location" },
            { label: "Rude behaviour of Rider", value: "Rude behaviour of Rider" },
            { label: "Vomit", value: "Vomit" },
            { label: "Excess Luggage", value: "Excess Luggage" },
            { label: "Travel with Pet", value: "Travel with Pet" },
            { label: "Request of prohibited delivery", value: "Request of prohibited delivery" },
            { label: "Medical emergency", value: "Medical emergency" }
        ],
        "Ride Related" : [
            { label: "Parking not available", value: "Parking not available" },
            { label: "Road Blocked/ Narrow Lane towards - Pickup / Drop Location", value: "Road Blocked/ Narrow Lane towards - Pickup / Drop Location" },
            { label: "High Traffic", value: "High Traffic" },
            { label: "Water Logged Area", value: "Water Logged Area" },
            { label: "Pickup related issue", value: "Pickup related issue" },
            { label: "Rental ride extension", value: "Rental ride extension" },
            { label: "Rider wants to travel NSR region", value: "Rider wants to travel NSR region" },
            { label: "Long Distance", value: "Long Distance" },
            { label: "Low SOC", value: "Low SOC" }
        ],
        "Tech Related" : [
            { label: "Unable to mark 'Stop' in Rental ride", value: "Unable to mark 'Stop' in Rental ride" },
            { label: "GPS / Map not working", value: "GPS / Map not working" },
            { label: "Unable to start / end", value: "Unable to start / end" }
        ],
        "Emergency Logout" : [
            { label: "Mobile related Issue", value: "Mobile related Issue" },
            { label: "Family Emergency", value: "Family Emergency" },
            { label: "Self health issue", value: "Self health issue" }
        ]

}

